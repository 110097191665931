<template>
  <div class="cart">
    <!-- <title-top>购物车</title-top> -->
    <div class="row align-center bg-white h96 w100 position_f" style="top:0;max-width:750px;">
      <div class="row flex-center" style="width:1.173333rem;" @click="onBack">
        <img class="imgsize44" src="~images/fanhui.png" alt />
      </div>
      <span class="flex1 row flex-center font36 f-1A1A1A">购物车</span>
      <span class="font24 f-333333" style="width: 1.333333rem;" v-if="!isEdit" @click="onFlag">编辑</span>
      <span class="font24 f-333333" style="width: 1.333333rem;" v-else @click="onFlag">完成</span>
    </div>
    <div class="line"></div>
    <div class="h72"></div>
    <div class="row align-center bg-white" v-for="(item,index) in res.data" :key="index">
      <img
        v-if="(isEdit && !item.isSelected)"
        class="imgsize32 ml16"
        src="~images/not-sel.png"
        @click="onSelect(index)"
      />
      <img
        v-if="(isEdit && item.isSelected)"
        class="imgsize32 ml16"
        src="~images/selecticon.png"
        @click="onSelect(index)"
        alt
      />
      <img class="imgsize176 ml16 mr25" :src="item.image_url" alt @click="onSelect(index)" />
      <div class="flex1 column">
        <p class="ellipsis font24 f-1A1A1A mt50 mr25">{{item.product_name}}</p>
        <span class="font32 f-F62341 mt20">¥{{item.price}}</span>
        <div class="row between align-center mb20">
          <span class="font24 f-333333">数量</span>
          <div class="row align-center">
            <span
              class="row flex-center font60 biankuang"
              @click="onReduce(item.id,item.quantity)"
            >-</span>
            <span class="row flex-center font32 f-333333 biankuang szsize">{{item.quantity}}</span>
            <span
              class="row flex-center font36 biankuang brr mr25"
              @click="onAdd(item.id,item.quantity)"
            >+</span>
          </div>
        </div>
      </div>
    </div>
    <div class="h96"></div>
    <div
      class="row between bg-white w100 align-center h80 position_f"
      style="bottom:49px;max-width:750px;"
      v-if="!isEdit"
    >
      <div class="row font28 ml25">
        <span>合计：</span>
        <span class="f-F62341">￥{{res.total_price}}</span>
      </div>
      <span class="row flex-center font28 f-white bg-41ABA7 jssize" @click="onSure">结算</span>
    </div>
    <div
      v-else
      class="row between bg-white w100 align-center h80 position_f"
      style="bottom:49px;max-width:750px;"
    >
      <div class="row align-center font28 ml25">
        <img
          v-if="!isAll"
          class="imgsize32 mr25"
          src="~images/not-sel.png"
          @click="onAll"
        />
        <img v-else class="imgsize32 mr25" src="~images/selecticon.png" @click="onAll" alt />
        <span>全选</span>
      </div>
      <span class="row flex-center font28 f-white bg-41ABA7 jssize" @click="onDelete">删除</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { cartList, updateCart, deleteCart } from "network/igou2";

export default {
  name: "Cart",
  data() {
    return {
      res: {},
      isEdit: false,
      isAll: false,
      cart_item_ids:''
    };
  },
  mounted() {
    this._cartList();
  },
  methods: {
    onBack(){
      this.$router.back();
    },
    onSelect(index) {
      let res = this.res;
      let list = this.res.data;
      list[index].isSelected = !list[index].isSelected;
      res.data = list;
      this.res = res;
      if (this.fn_isAll()) {
        this.isAll = true;
      } else {
        this.isAll = false;
      }
    },
    onFlag() {
      this.isEdit = !this.isEdit;
    },
    _cartList() {
      cartList().then(result => {
        console.log(result);
        let list = result.data;
        for (let i = 0; i < list.length; i++) {
          list[i].isSelected = false;
        }
        result.data = list;
        this.cart_item_ids = list.map(t=> t.id).join(',');
        this.res = result;
      });
    },
    _updateCart(id, num) {
      updateCart(id, num).then(result => {
        console.log(result);
        this._cartList();
      });
    },
    _deleteCart(id) {
      deleteCart(id).then(result => {
        console.log(result);
        this._cartList();
      });
    },
    onDelete() {
      //1,判断是否有商品被选择
      let list = this.res.data;
      let ids = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].isSelected) {
          ids.push(list[i].id);
        }
      }
      if (ids.length == 0) {
        this.$toast.show("请选择需要删除的商品");
        return;
      }
      for (let i = 0; i < ids.length; i++) {
        this._deleteCart(ids[i]);
      }
    },
    onSure(){
      this.$router.push({
        path:'/zyorder',
        query:{cart_item_ids:this.cart_item_ids}})
    },
    onReduce(id, num) {
      if (num - 1 == 0) {
        return;
      }
      this._updateCart(id, num - 1);
    },
    onAdd(id, num) {
      this._updateCart(id, num + 1);
    },
    onAll() {
      this.isAll = !this.isAll;
      if (this.isAll) {
        this.fn_all();
      } else {
        this.fn_all_un();
      }
    },
    fn_all() {
      let res = this.res;
      let list = this.res.data;
      for (let i = 0; i < list.length; i++) {
        list[i].isSelected = true;
      }
      res.data = list;
      this.res = res;
    },
    fn_all_un() {
      let res = this.res;
      let list = this.res.data;
      for (let i = 0; i < list.length; i++) {
        list[i].isSelected = false;
      }
      res.data = list;
      this.res = res;
    },
    fn_isAll() {
      let list = this.res.data;
      for (let i = 0; i < list.length; i++) {
        if (!list[i].isSelected) {
          return false;
        }
      }
      return true;
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.biankuang {
  border: 1px solid #333333;
  width: 0.746667rem;
  height: 0.593333rem;
  border-radius: 0.106667rem 0 0 0.106667rem;
}
.szsize {
  width: 1.08rem;
  height: 0.593333rem;
  border-radius: 0;
  border-left: 0;
}
.brr {
  border-left: 0;
  border-radius: 0 0.106667rem 0.106667rem 0;
}
.jssize {
  height: 100%;
  width: 2.4rem;
}
</style>
